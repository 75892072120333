.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

thead {
  background-color: #f2f2f2;
}

th, td {
  padding: 8px;
  text-align: left;
}

th {
  font-weight: bold;
}

td:first-child {
  width: 15%;
}

td:nth-child(2) {
  width: 15%;
}

td:nth-child(3) {
  width: 35%;
}

td:nth-child(4) {
  width: 10%;
}

td:nth-child(5) {
  width: 10%;
}

td:nth-child(6) {
  width: 5%;
}

td:nth-child(7) {
  width: 5%;
}

td:nth-child(8) {
  width: 5%;
}

tbody tr:first-child td {
  border-top: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}


/* circle */
.circle {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
}

.orange {
  background-color: #ffa500;
  color: #fff;
}

.red {
  background-color: #e53935;
  color: #fff;
}

.green {
  background-color: #43a047;
  color: #fff;
}

.circle i {
  vertical-align: middle;
  font-size: 8px;
}

.circle .fa-check {
  display: block;
}

.circle .fa-times {
  display: block;
}

.circle .fa-exclamation {
  display: block;
}
